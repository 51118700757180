loadScriptOnDemand('https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js', function() {
	window.DD_RUM && window.DD_RUM.init({
		clientToken: $('#dataDogRumConfig').data('datadog-client-token'),
		applicationId: $('#dataDogRumConfig').data('datadog-application-id'),
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: 'datadoghq.com',
		service: $('#dataDogRumConfig').data('datadog-service'),
		env: $('#dataDogRumConfig').data('datadog-env'),
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		allowedTracingUrls: [window.location.origin],
		sessionSampleRate: Number($('#dataDogRumConfig').data('datadog-sample-rate')),
		sessionReplaySampleRate: Number($('#dataDogRumConfig').data('datadog-replay-sample-rate')),
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input',
	});
});
